.login {
  form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    flex-direction: column;
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 15.8033px;
      //   line-height: 14px;
    }
    input {
      width: 200px;
      height: 10px;
      border: 0.737705px solid #8692a6;
      border-radius: 4.42623px;
      padding: 20px;
      margin: 20px;
    }
    button {
      margin-top: 10px;
      padding: 10px;
      width: 200px;
      border-radius: 4.42623px;
      border-style: none;
      cursor: pointer;
      background-color: #ff5e0d;
      font-size: 15.8033px;
      color: white;
    }
  }
}
