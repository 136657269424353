.home {
  display: flex;

  .homeContainer {
    flex: 6;
    .seeDetails {
      @apply text-tramangoOrange;
      display: flex;
      width: 99.5%;
      font-size: 0.8rem;
      cursor: pointer;
      justify-content: flex-end;
      align-items: center;
      gap: 5px;
      .icon {
        font-size: 0.8em;
      }
    }
    .flightItinerary {
      border-top: 1px solid;
      @apply bg-white border-greyLight;
      max-width: 900px;
      margin: 20px auto 0;
      padding: 20px;
      .bottom {
        margin-bottom: 10px;
        .departureDetails {
          display: flex;
          align-items: stretch;
          margin-bottom: 20px;
          flex-direction: column;
          .airlineDetails {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            min-width: 20%;
            max-width: 20%;
            margin-right: 50px;
            .airlineDetailsLogo {
              margin-right: 10px;
              margin-bottom: 10px;
              position: relative;
              img {
                min-width: 30px;
                min-height: 30px;
                border-radius: 50%;
              }
            }
            .airlineDetailsName {
              font-size: 0.8rem;
              color: #424950;
              p {
                margin-right: 5px;
              }
            }
          }

          .flightDetails {
            flex-grow: 1;
            display: flex;
            justify-content: space-between;

            .flightDetailsLocation,
            .flightDetailsDestination {
              width: 35%;
              p:first-child {
                font-weight: 500;
                color: #2f2c2c;
              }
              :not(p:first-child) {
                color: #424950b0;
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 14px;
                margin-bottom: 7px;
              }
            }

            .flightDetailsTime {
              width: 30%;
              margin: 0;
              font-size: 0.75rem;
              font-weight: 500;
              p {
                text-align: center;
              }
              p:first-child {
                // border-bottom: 2px solid rgba(255, 94, 13, 0.3);
                padding-bottom: 7px;
              }
              .stopoverTime {
                margin-top: 13px;
                padding: 5px 7px;
                background-color: #42495012;
                border-radius: 3px;
              }
            }

            .flightDetailsLocation {
              margin-right: 10px;
            }
            .flightDetailsDestination {
              text-align: right;
              margin-left: 10px;
            }

            .bottom {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              color: #424950;
              font-size: 1rem;
              font-weight: 500;
              text-align: left;
              margin-top: 10px;
              p:nth-child(1) {
                margin: 0 20px;
              }
            }
          }
          @media screen and (max-width: 860px) {
            flex-direction: column;
            .airlineDetails {
              align-self: flex-start;
              margin-bottom: 10px;
              min-width: none;
              max-width: none;
              p {
                display: inline;
              }
            }
            .flightDetails {
              width: 100%;
            }
          }
        }
      }
      @media screen and (max-width: 550px) {
        .bottom {
          .departureDetails {
            .flightDetails {
              flex-direction: column;

              .flightDetailsTime,
              .flightDetailsLocation,
              .flightDetailsDestination {
                margin-left: 0;
                margin-bottom: 10px;
                width: 100%;
                max-width: 100%;
                text-align: left;
              }
              .flightDetailsTime {
                display: flex;

                flex-direction: column;
                gap: 10px;

                .stopoverTime {
                  margin-top: 0px;
                  margin-left: 7px;
                }
              }
            }
          }
        }
      }
    }
  }
  .widgets,
  .charts {
    display: flex;
    padding: 20px;
    gap: 20px;
  }

  .charts {
    padding: 5px 20px;
  }

  .listContainer {
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 20px;
    margin: 20px;

    .listTitle {
      font-weight: 500;
      color: gray;
      margin-bottom: 15px;
    }
  }
}

.itinerary {
  background-color: #f4f4f4;
  max-width: fit-content;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 8px;
  .title {
    // font-weight: 700;
    display: flex;
    gap: 4px;
    text-decoration: underline;
  }
  .infos {
    .section {
      display: flex;
      gap: 50px;
      align-items: center;
      padding-top: 10px;
    }
  }
}

.flex {
  display: flex;
  gap: 10px;
}

.flexcol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}